/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@chakra-ui/react';
import { SearchResults } from '../../components/SearchResults';
import SEO from '../../components/SEO';
import { HeaderStyles, SearchHeaderStyles } from '../search';
import { PageStyles } from '..';

export default function SearchComediansPage({ location, data }) {
    const comedians = data.comedians.nodes;
    let searchTerm = location ? location.search : null;
    if (searchTerm.length > 1 && searchTerm.charAt(2) === '=') {
      searchTerm = searchTerm.slice(3);
    } else {
      searchTerm = null;
    }
  if (!searchTerm) {
    return <HeaderStyles><span className="bullet">&bull;</span> Your search term didn't make it. Try again!</HeaderStyles>
  }
  const searchTermsArr = searchTerm?.toLowerCase().split('-');

  const comediansFiltered = [...comedians].filter(comedian => {
    let isMatch = false;
    searchTermsArr.find(term => {
      const regEx = (new RegExp(term));
      const termMatched = regEx.test(comedian.firstName.toLowerCase()) || regEx.test(comedian.lastName.toLowerCase()); 
      if (termMatched) {
        isMatch = true;
      }
    })
    if (isMatch) {
      return comedian;
    }
  });
  const {length} = comediansFiltered;

  return (
    <>
        <SEO title="Search comedians | Yuks University" />
        <PageStyles>
          <SearchHeaderStyles>
            <SearchResults
            resultsArr={comediansFiltered}
            searchTerm={searchTerm}
            type="Comedian"
            totalLength={length}
            />
           </SearchHeaderStyles>
        </PageStyles>
    </>
  );
}

export const query = graphql`
  query {
    comedians: allSanityComedian(sort: { fields: lastName, order: ASC }) {
      nodes {
        id
        firstName
        lastName
        slug {
          current
        }
        avatar {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        categories {
            id
            category
            slug {
                current
            }
        }
      }
    }
  }
`;